body {
  background: #fff;
  font: 16px/24px "Montserrat", sans-serif; }

a {
  color: #2C9B94;
  text-decoration: none; }

a:hover {
  color: #30CCC2; }

h1, h2, h3, h4, h5, h6 {
  color: #2C9B94; }

h1 {
  font-size: 42px;
  line-height: 50px;
  color: #fff; }

h2 {
  font-size: 40px;
  line-height: 49px;
  color: #fff; }

h3 {
  font-size: 24px;
  line-height: 29px;
  color: #fff; }

h4 {
  font-size: 24px;
  line-height: 32px;
  color: #2C9B94;
  font-weight: bold; }

h5 {
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  font-weight: bold; }

h6 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-weight: bold; }

p {
  font: 16px/24px "Open Sans", sans-serif; }

.title20 {
  font-size: 20px;
  line-height: 28px;
  color: #2C9B94; }

.title18 {
  font-size: 18px;
  line-height: 28px;
  color: #2C9B94; }

.title16 {
  font-size: 16px;
  line-height: 24px;
  color: #2C9B94;
  font-weight: bold; }

header {
  background: #fff url("../img/hero-bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
  position: relative;
  padding: 40px 0 0; }
  header .header-content {
    padding-top: 40px; }
  header .block-angle {
    background-color: #fff;
    height: 100px;
    position: relative;
    z-index: 1;
    margin-top: -20px; }
    header .block-angle:before {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; }
    header .block-angle:before {
      top: 0;
      transform: skewY(-2deg);
      transform-origin: 0 0; }
  header .hand {
    margin-bottom: 23px; }
  header nav {
    float: right; }
    header nav li {
      margin-left: 10px; }
    header nav a {
      color: #fff;
      text-transform: uppercase; }
  header .logo {
    margin-top: -15px; }

section {
  padding: 40px; }

.section-title {
  text-align: center; }

.section-feature {
  padding-top: 60px; }
  .section-feature .feature-item {
    text-align: center; }
    .section-feature .feature-item img {
      height: 50px; }
    .section-feature .feature-item .title20 {
      margin: 20px 0 10px; }

.section-howitworks h2 {
  font-size: 24px;
  line-height: 32px;
  text-align: center; }
.section-howitworks #carousel-partners .item {
  width: 30%;
  height: 100px;
  float: left; }
.section-howitworks #carousel-partners .item img {
  width: 100%; }
.section-howitworks .owl-carousel .owl-controls {
  margin: 20px auto;
  text-align: center;
  width: 270px; }
.section-howitworks .owl-carousel .owl-controls span {
  width: 250px; }
.section-howitworks .owl-carousel .owl-controls .owl-nav .owl-prev,
.section-howitworks .owl-carousel .owl-controls .owl-nav .owl-next,
.section-howitworks .owl-carousel .owl-controls span {
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
  color: #fff;
  opacity: 1;
  float: left; }
.section-howitworks .active-elem {
  background: url("../img/how/phone-how.png") no-repeat; }
.section-howitworks .item {
  width: 228px;
  height: 456px;
  padding: 47px 14px;
  margin: 0 auto; }
  .section-howitworks .item img {
    height: 361px;
    width: 202px; }

.background-green {
  background: #30CCC2;
  color: #fff; }
  .background-green h1, .background-green h2, .background-green h3, .background-green h4, .background-green h5, .background-green h6, .background-green p {
    color: #fff; }
  .background-green .btn {
    border: 1px solid #fff;
    color: #fff; }

.btn-lg {
  padding: 15px 40px; }

.section-partners {
  padding-top: 0;}

.section-title {
  text-align: center; }
.section-partners #partners-carousel {
  position: relative; }
  .section-partners #partners-carousel .partner-item {
    width: 100%; }
  .section-partners #partners-carousel .partner-list {
    width: 30%;
    float: left;
    overflow: hidden;
    margin: 1%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #FAFAFA;
    border-radius: 5px; }
    .section-partners #partners-carousel .partner-list img {
      width: 100%;
      height: 180px; }
    .section-partners #partners-carousel .partner-list .partner-caption {
      background: #FAFAFA;
      padding: 10px; }
  .section-partners #partners-carousel .owl-stage {
    padding: 0 20px; }
  .section-partners #partners-carousel .owl-nav > div {
    font-size: 24px;
    font-weight: bold;
    background: transparent;
    color: #FCA280; }
  .section-partners #partners-carousel .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: 0; }
  .section-partners #partners-carousel .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: 0; }

.section-dashboard .section-title {
  text-align: left; }
.section-dashboard .merchant {
  width: 100%;
  margin-top: 10px; }
.section-dashboard p {
  margin: 20px 0; }
.section-dashboard .dashboard-feature {
  text-align: center;
  margin-top: 40px; }
  .section-dashboard .dashboard-feature li {
    width: 18%;
    text-align: center; }
  .section-dashboard .dashboard-feature img {
    text-align: center; }

.section-subscribe {
  background: #fff url("../img/girl.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 0; }
  .section-subscribe .block-angle {
    background-color: #30CCC2;
    width: 100%;
    height: 120px;
    padding-top: 60px;
    text-align: center;
    position: relative;
    z-index: 1; }
    .section-subscribe .block-angle:before {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; }
    .section-subscribe .block-angle:before {
      top: 0;
      transform: skewY(2deg);
      transform-origin: 100% 0; }
    .section-subscribe .block-angle .list-inline-center li {
      padding: 20px 20px; }
      .section-subscribe .block-angle .list-inline-center li.footer-logo {
        padding: 0 20px; }
  .section-subscribe .subscribe-content {
    padding: 100px 0 150px;
    text-align: center; }
  .section-subscribe ul.list-inline-center {
    margin-top: -40px; }
    .section-subscribe ul.list-inline-center .social {
      height: 35px; }
  .section-subscribe form {
    margin-top: 20px;
    border: 0; }
    .section-subscribe form input {
      border: 0; }
    .section-subscribe form .email {
      padding: 14px;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      font-family: "Open Sans", sans-serif;
      border-radius: 5px 0 0 5px;
      width: 400px;
      height: 50px; }
    .section-subscribe form .btn {
      background: #FCA280;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      padding: 10px 20px;
      border-radius: 0 5px 5px 0;
      border-color: #FCA280;
      margin-left: -5px;
      margin-top: -4px;
      line-height: 30px;
      height: 50px; }

footer {
  padding-top: 0;
  margin-top: -10px;
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 24px;
  color: ""; }
  footer .footer-links {
    text-align: center; }
    footer .footer-links a {
      color: #fff;
      text-transform: uppercase; }
  footer .copyright {
    text-align: center;
    color: rgba(0, 0, 0, 0.12); }

.owl-pagination {
  display: none; }

.sm-orange {
  border-top: 2px solid #FCA280;
  width: 80px;
  margin-top: 20px;
  margin-bottom: 20px; }
  .sm-orange hr {
    height: 2px;
    border: 0;
    border-bottom: 2px solid #FCA280;
    width: 50%;
    margin: 10px 0; }
  .sm-orange.center {
    text-align: center;
    margin: 20px auto; }
    .sm-orange.center hr {
      margin: 10px auto; }
  .sm-orange.white {
    border-color: #fff; }
    .sm-orange.white hr {
      border-color: #fff; }

ul.list-inline-center {
  list-style: none;
  display: inline-block;
  margin: 20px auto;
  padding: 0;
  zoom: 1;
  *display: inline; }

ul.list-inline-center li {
  float: left;
  padding: 2px 5px;
  padding: 0 10px; }

@media only screen and (max-width: 500px) {
  .section-header .header-content .app-link img {
    width: 40%; }

  .section-partners #partners-carousel .partner-list {
    width: 100%; }
  .section-partners #partners-carousel .owl-stage {
    padding: 0; }

  .section-subscribe form .email, .section-subscribe form .btn {
    width: 80%;
    border-radius: 5px;
    margin: 5px 0; }
  .section-subscribe .social {
    height: 10px; }
  .section-subscribe .block-angle .list-inline-center li {
    padding-left: 10px;
    padding-right: 10px; }

  .section-dashboard .dashboard-feature li {
    width: 40%; } }

/*# sourceMappingURL=style.css.map */
